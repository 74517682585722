import React from "react";

import { useAuth0 } from "@auth0/auth0-react";

import { NavLink } from "react-router-dom";
import UserInfo from "./Components/UserInfo/UserInfo";
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarRadioGroup,
  MenubarSub,
  MenubarSubContent,
  MenubarSubTrigger,
  MenubarTrigger,
} from "./Components/UiComponents/Menubar";
import { Menu, MenubarMenu } from "@radix-ui/react-menubar";

interface Props {}

const routes = [
  {
    path: "/patientsmap",
    title: "PatientsMap",
    exact: true,
    main: () => <h1>TourManager /</h1>,
  },
  // {
  //   path: "/patientinfoscreen",
  //   title: "Patient Info",
  //   exact: true,
  // },
  {
    path: "/editmobilemission",
    title: "On Tour Manager",
    exact: true,
    //main: () => <h1>OnTourManager /</h1>,
  },
  // {
  //   path: "/patientmapanalyzis",
  //   title: "Wer Wo Wann",
  //   exact: true,
  //   //main: () => <h1>OnTourManager /</h1>,
  // },
  {
    path: "/missioncreate",
    title: "Neuer Einsatz",
    exact: true,
    //main: () => <h1>OnTourManager /</h1>,
  },
  {
    path: "/missionpublicationstatus",
    title: "Wochen Status",
    exact: true,
    //main: () => <h1>OnTourManager /</h1>,
  },
  {
    path: "/memberqualificationedit",
    title: "Qualifikation Edit",
    exact: true,
  },
  //{
  //   path: "/patientissue",
  //   title: "EumMins",
  //   exact: true,
  // },
  {
    path: "/missiontimeline",
    title: "NEW Timeline",
    exact: true,
  },
  {
    path: "/planschedule",
    title: "Leistungen Planen",
    exact: true,
  },
];

const Navigation = (props: Props) => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  console.log("Auth", isAuthenticated);
  console.log("User", user);
  console.log("Loading", isLoading);

  return (
    <nav className="flex z-0 justify-between bg-secondaryBackground ">
      <div className="flex h-15 items-center">
        <div className="flex items-center">
          <img
            alt="Logo"
            className="w-20 h20 mx-2 p-2"
            src="https://www.pzh.de/fileadmin/templates/public/img/logo_big_red.png"
          />
        </div>
        <div>
          <Menubar>
            <MenubarMenu>
              <MenubarTrigger>Patient</MenubarTrigger>
              <MenubarContent>
                <MenubarItem>
                  <NavLink to="/patientinfoscreen" reloadDocument={true}>
                    Patient Info
                  </NavLink>
                </MenubarItem>
                <MenubarRadioGroup value="patient">
                  <MenubarSub>
                    <MenubarSubTrigger>Leistungen Planen</MenubarSubTrigger>
                    <MenubarSubContent>
                      <MenubarItem>
                        <NavLink
                          to="/manageschedules/2710"
                          reloadDocument={true}
                        >
                          Hier
                        </NavLink>
                      </MenubarItem>
                      <MenubarItem>
                        <NavLink
                          to="/manageschedules/2710"
                          target="_blank"
                          className="cursor-pointer"
                          reloadDocument={true}
                        >
                          Neuer Modus
                        </NavLink>
                      </MenubarItem>
                    </MenubarSubContent>
                  </MenubarSub>

                  <MenubarItem>
                    <NavLink to="/analyzemission" reloadDocument={true}>
                      Leistungen Verwalten
                    </NavLink>
                  </MenubarItem>
                  <MenubarItem>
                    <NavLink to="/managepatientabsence" reloadDocument={true}>
                      Abwesenheit
                    </NavLink>
                  </MenubarItem>
                </MenubarRadioGroup>
              </MenubarContent>
            </MenubarMenu>
            <MenubarMenu>
              <MenubarTrigger>Einsätze</MenubarTrigger>
              <MenubarContent>
                <MenubarRadioGroup value="einsatz">
                  {/* <MenubarItem>
                    <NavLink to="/weeklyoverview" reloadDocument={true}>
                      Import Excel
                    </NavLink>
                  </MenubarItem> */}
                  <MenubarItem>
                    <NavLink to="/importmissioncsv" reloadDocument={true}>
                      Excel Import
                    </NavLink>
                  </MenubarItem>

                  <MenubarItem>
                    <NavLink to="/tourmanager" reloadDocument={true}>
                      Touren Planer
                    </NavLink>
                  </MenubarItem>
                  <MenubarItem>
                    <NavLink to="/calendar" reloadDocument={true}>
                      Touren Woche
                    </NavLink>
                  </MenubarItem>
                  <MenubarItem>
                    <NavLink to="/missioneasyplan" reloadDocument={true}>
                      Touren Easy
                    </NavLink>
                  </MenubarItem>
                  <MenubarItem>
                    <NavLink
                      to="/viewermissioneasyplan"
                      target="_blank"
                      className="cursor-pointer"
                      reloadDocument={true}
                    >
                      Karte Touren Easy
                    </NavLink>
                  </MenubarItem>
                  <MenubarItem>
                    <NavLink to="/tourpublish" reloadDocument={true}>
                      Touren Mobile
                    </NavLink>
                  </MenubarItem>
                </MenubarRadioGroup>
              </MenubarContent>
            </MenubarMenu>
            <MenubarMenu>
              <MenubarMenu>
                <MenubarTrigger>Mobile</MenubarTrigger>
                <MenubarContent>
                  <MenubarItem>
                    <NavLink to="/reviewexecutionmobile" reloadDocument={true}>
                      Woche Überarbeiten
                    </NavLink>
                  </MenubarItem>
                  <MenubarItem>
                    <NavLink to="/reviewonedayexecution" reloadDocument={true}>
                      Tag Überarbeiten
                    </NavLink>
                  </MenubarItem>
                </MenubarContent>
              </MenubarMenu>
              <MenubarTrigger>Analysen</MenubarTrigger>
              <MenubarContent>
                <MenubarRadioGroup value="analysen">
                  <MenubarItem>
                    <NavLink
                      to="/patientdurationoverview"
                      reloadDocument={true}
                    >
                      Zeiten
                    </NavLink>
                  </MenubarItem>
                  <MenubarItem>
                    <NavLink to="/patientissue" reloadDocument={true}>
                      EuMins
                    </NavLink>
                  </MenubarItem>

                  <MenubarItem>
                    <NavLink to="/tourmanager" reloadDocument={true}>
                      Touren Planer
                    </NavLink>
                  </MenubarItem>
                  <MenubarItem>
                    <NavLink to="/calendar" reloadDocument={true}>
                      Touren Woche
                    </NavLink>
                  </MenubarItem>
                </MenubarRadioGroup>
              </MenubarContent>
            </MenubarMenu>
          </Menubar>
        </div>
        <div className="flex justify-evenly items-center">
          {routes.map((route, index) => {
            return (
              <NavLink
                key={index}
                to={route.path}
                reloadDocument={true}
                className="active:text-red-500   px-3 hover:border-b-2 hover:border-primary"
              >
                {route.title}
              </NavLink>
            );
          })}
        </div>
      </div>
      <div className="flex items-center m-2 p-2">
        <UserInfo />
      </div>
    </nav>
  );
};

export default Navigation;
