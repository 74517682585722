import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  LocalMission_MissionEasyPlan,
  LocalTourPauses_MissionEasyPlan,
  MissionPause,
  TourInfo_MissionEasyPlan,
  useMissionEasyPlan,
} from "./useMissionEasyPlan";
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  PointerSensor,
  useDraggable,
  useDroppable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button } from "../UiComponents/Button";
import { cn } from "../../helpers/utils";
import {
  BriefcaseMedicalIcon,
  CatIcon,
  Circle,
  CircleMinusIcon,
  CirclePlusIcon,
  CoffeeIcon,
  DoorOpen,
  Edit2Icon,
  FilePlusIcon,
  HeartPulseIcon,
  InfoIcon,
  MapPinIcon,
  MenuIcon,
  ReplaceIcon,
  ShipWheelIcon,
  Syringe,
  Text,
  Trash,
} from "lucide-react";
import _, { get, set } from "lodash";

import DisplayMemberShortName from "../Member/DisplayMemberShortName";
import { BiMale, BiMaleFemale } from "react-icons/bi";
import { FaMale } from "react-icons/fa";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import { BsQuestionCircleFill } from "react-icons/bs";
import dayjs from "dayjs";
import { Popover } from "../UiComponents/Popover";
import { PopoverContent, PopoverTrigger } from "@radix-ui/react-popover";
import EditMission from "./EditMission";
import { Socket, io } from "socket.io-client";
import inMemoryJwt from "../Auth0/inMemoryJwt";
import toast from "react-hot-toast";
import ChangeMember from "./ChangeMember";
import TourInfoMessageDialog from "../TourManager/TourInfoMessageDialog";
import { Dialog, DialogContent, DialogTrigger } from "../UiComponents/Dialog";
import CreateNewMission from "./CreateNewMission";
import { Helmet } from "react-helmet-async";
import CreateNewTour from "./CreateNewTour";
import { MyDimmer } from "../UiComponents/MyDimmer";
import DisplayActivePatientAbsence from "../Patient/PatientAbsence/DisplayActivePatientAbsence";
import { Calendar } from "../UiComponents/Calendar";

const timeIntervals = [
  {
    startMinuteOfDay: 360,
    endMinuteOfDay: 479,
    title: "06-08",
    color: "border-b-blue-100",
  },
  {
    startMinuteOfDay: 480,
    endMinuteOfDay: 599,
    title: "08-10",
    color: "border-b-green-100",
  },
  {
    startMinuteOfDay: 600,
    endMinuteOfDay: 719,
    title: "10-12",
    color: "border-b-yellow-100",
  },
  {
    startMinuteOfDay: 720,
    endMinuteOfDay: 899,
    title: "12-15",
    color: "border-b-red-100",
  },
  {
    startMinuteOfDay: 900,
    endMinuteOfDay: 1079,
    title: "15-18",
    color: "border-b-purple-100",
  },
  {
    startMinuteOfDay: 1080,
    endMinuteOfDay: 1259,
    title: "18-21",
    color: "border-b-pink-100",
  },
  {
    startMinuteOfDay: 1260,
    endMinuteOfDay: 1439,
    title: "21-24",
    color: "border-b-blue-100",
  },
];

const startMin2Time = (startMinuteOfDay: number) => {
  const hour = Math.floor(startMinuteOfDay / 60)
    .toString()
    .padStart(2, "0");
  const minute = (startMinuteOfDay % 60).toString().padStart(2, "0");

  return `${hour}:${minute}`;
};

export const SortableMission = ({
  mission,
  tourInfo,
  time2nextMission,
}: {
  mission: MissionPause;
  tourInfo: TourInfo_MissionEasyPlan | undefined;
  time2nextMission: number;
}) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: mission.id,
      data: {
        type: "mission",
        mission,
      },
    });

  const { setNodeRef: dropNodeRef, isOver } = useDroppable({
    id: mission.id,
    data: {
      type: "mission",
      mission,
    },
  });

  const {
    min_3_PatientsIds,
    min_5_PatientsIds,
    min_7_PatientsIds,
    min_12_PatientsIds,
    calculateNearestPatients,
    clearNearestPatients,
    selectedPatientId,
    selectedMissionId,
    setSelectedPatientId,
    setSelectedMissionId,
    selectedTourId,
    setSelectedMission,
    moveMissions,
    touchedMissionIds,
  } = useMissionEasyPlan();

  const style = {
    transform: CSS.Transform.toString(transform),
    //transition,
  };

  const within_3_Distance = (patientId: string) => {
    return min_3_PatientsIds.includes(patientId);
  };

  const within_5_Distance = (patientId: string) => {
    return min_5_PatientsIds.includes(patientId);
  };

  const within_7_Distance = (patientId: string) => {
    return min_7_PatientsIds.includes(patientId);
  };

  const within_12_Distance = (patientId: string) => {
    return min_12_PatientsIds.includes(patientId);
  };

  const timeColor = timeIntervals.find(
    (timeInterval) =>
      mission.startMinuteOfDay >= timeInterval.startMinuteOfDay &&
      mission.startMinuteOfDay <= timeInterval.endMinuteOfDay
  )?.color;

  const missionIsMoved = useMemo(() => {
    return moveMissions.find(
      (moveMission) => moveMission.missionId === mission.id
    );
  }, [moveMissions]);

  const missionIsTouched = useMemo(() => {
    return touchedMissionIds.find((missionId) => missionId === mission.id);
  }, [moveMissions]);

  //console.log();

  return (
    <div ref={dropNodeRef} className={cn("")}>
      <div
        onClick={() => {
          if (selectedMissionId !== mission.id) {
            setSelectedPatientId(mission.patientId);
            setSelectedMissionId(mission.id);
            setSelectedMission(mission);
            if (selectedTourId === mission.tourId) {
              clearNearestPatients();
              calculateNearestPatients(mission.patientId);
            }
          } else {
            setSelectedPatientId("");
            setSelectedMissionId("");
            setSelectedMission(undefined);
          }
        }}
        className={cn(
          "bg-white px-2 border-2 border-white  border-b-4 flex flex-col items-stretch",
          //timeColor,
          {
            "bg-blue-500 text-white": isOver,
            "opacity-40": isDragging,
            "bg-purple-100 ": within_12_Distance(mission.patientId),
            "bg-red-100": within_7_Distance(mission.patientId),
            "bg-yellow-100": within_5_Distance(mission.patientId),
            "bg-green-100": within_3_Distance(mission.patientId),
            "bg-blue-800 text-white": mission.isModifiedInDatabase,
            "bg-red-800 text-white": mission.executionMobiles.length > 0,
            "bg-green-500 text-white": mission.isNewCreated,
            "bg-black text-white": mission.hasConflictAbsence,
            "border-2 border-blue-500 ":
              selectedPatientId === mission.patientId,
            "border-2 border-green-500 ": selectedMissionId === mission.id,
          }
        )}
        ref={setNodeRef}
        //style={style}
        {...attributes}
        {...listeners}
      >
        <div className={cn("text-xs font-extralight w-24", {})}>
          <div
            className={cn(
              {
                "bg-green-50": mission.patient?.isPrivate,
                "bg-purple-500 text-white":
                  Number(tourInfo?.member?.hasQualificationLevel) <
                    mission.minReqQualification &&
                  !mission.patient?.isPrivate &&
                  mission.executionMobiles.length === 0,
              },
              {
                "bg-green-50": mission.patient?.isPrivate,
                "bg-purple-500 text-black":
                  Number(tourInfo?.member?.hasQualificationLevel) <
                    mission.minReqQualification &&
                  !mission.patient?.isPrivate &&
                  mission.executionMobiles.length > 0,
              }
            )}
          >
            {mission.patient?.shortName}({mission.patientId})
          </div>
        </div>
        <div className="text-xs font-bold flex flex-row justify-between items-center">
          <div
            className={cn(
              "text-xs  font-extralight p-1 rounded-t-sm",

              { "text-yellow-600": mission.minReqQualification > 2 },
              { "text-yellow-300": mission.minReqQualification <= 2 },
              { "text-blue-500": mission.minReqQualification === 0 },
              { "text-red-500": mission.minReqQualification >= 3 }
            )}
          >
            <div>
              {mission.minReqQualification !== -1 ? (
                <BriefcaseMedicalIcon size={14} />
              ) : (
                <BsQuestionCircleFill size="14" className="text-red-500" />
              )}
            </div>
          </div>

          <div>{startMin2Time(mission.startMinuteOfDay)}</div>
          <div
            className={cn(
              "rounded-xl px-1",
              { "bg-green-200": mission.duration_min <= 5 },
              {
                "bg-yellow-200":
                  mission.duration_min > 5 && mission.duration_min <= 10,
              },
              {
                "bg-red-200":
                  mission.duration_min > 10 && mission.duration_min <= 15,
              },
              { "bg-purple-200": mission.duration_min > 15 }
            )}
          >
            {mission.duration_min}
          </div>
        </div>
        <div className="flex flex-row gap-2 items-center">
          <Syringe className="" size={10} />
          <HeartPulseIcon className="" size={10} />
          <DoorOpen size={10} />
          <MenuIcon size={10} />
          <BiMaleFemale size={10} />
          {/* <CarFrontIcon size={10} /> */}
        </div>
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row">
            {/* {missionIsMoved ? (
              <div className="text-xs text-blue-500 rounded-full">m</div>
            ) : null} */}
            {mission.isTouched ? (
              <div className="text-xs text-gray-500 rounded-full">x</div>
            ) : null}
          </div>
          <div>
            {mission.patient?.latitude === null ? (
              <div className="text-xs text-red-500">geo</div>
            ) : null}
          </div>
          <div
            className={cn("text-xs text-right", {
              "text-red-500": time2nextMission > 11,
            })}
          >
            {"->"}
            {time2nextMission}
          </div>
        </div>
      </div>
    </div>
  );
};

const SortablePause = ({
  pause,
  tourInfo,
  time2nextMission,
}: {
  pause: MissionPause;
  tourInfo: TourInfo_MissionEasyPlan | undefined;
  time2nextMission: number;
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    //transition,
    isDragging,
  } = useDraggable({
    id: pause.id,
    data: {
      type: "pause",
      mission: pause,
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    //transition,
  };

  return (
    <div
      className="bg-white px-2 border-2 border-white  border-b-4 flex flex-col items-stretch"
      ref={setNodeRef}
      //style={style}
      {...attributes}
      {...listeners}
    >
      <div className="bg-cyan-400 text-white text-xs">
        <div>Pause</div>
        <div>{startMin2Time(pause.startMinuteOfDay)}</div>
        <div>{pause.duration_min}</div>
        <div>
          {"->"}
          {time2nextMission}
        </div>
      </div>
    </div>
  );
};

const SortableTour = ({ tourId }: { tourId: string }) => {
  const {
    tourInfos,
    missionsMap,
    notActiveMissionsMap,
    selectedTourId,
    setSelectedTourId,
    recalculateTour,
    setMissionStatus,
    setTourStartTime,
    createTourPause,
    updateMisisonsFromDB,
    setShowTourInfoMessageDialog,
    setTourInfoMessageTourId,
    setMonitorTourIds,
    monitorTourIds,
  } = useMissionEasyPlan();

  const { setNodeRef, isOver } = useDroppable({
    id: tourId,
    data: {
      type: "tour",
      tourId,
    },
  });

  const { setNodeRef: refDeleteMission, isOver: isOverDeleteMission } =
    useDroppable({
      id: "del_" + tourId,
      data: {
        type: "delete",
        tourId,
      },
    });

  const [tourMissions, setTourMissions] = useState(
    missionsMap?.get(tourId) || []
  );

  const [notActiveTourMissions, setNotActiveTourMissions] = useState(
    notActiveMissionsMap?.get(tourId) || []
  );

  const [tourInfo, setTourInfo] = useState<
    TourInfo_MissionEasyPlan | undefined
  >(tourInfos.find((tourInfo) => tourInfo.tourId === tourId));

  const [tourPauses, setTourPauses] =
    useState<LocalTourPauses_MissionEasyPlan>();

  useEffect(() => {
    // console.log("Use Effewct missionsMap", missionsMap?.get(tourId));
    setTourMissions(missionsMap?.get(tourId) || []);
  }, [missionsMap?.get(tourId)]);

  useEffect(() => {
    // console.log("Use Effewct missionsMap", missionsMap?.get(tourId));
    setNotActiveTourMissions(notActiveMissionsMap?.get(tourId) || []);
  }, [notActiveMissionsMap?.get(tourId)]);

  const _deferredRecalulateTour = useCallback(
    _.debounce(recalculateTour, 700),
    []
  );

  useEffect(() => {
    //console.log("Use Effewct tourInfos", tourInfos);
    setTourInfo(tourInfos.find((tourInfo) => tourInfo.tourId === tourId));
    // setTourPauses(tourInfos.find((tourInfo) => tourInfo.tourId === tourId)?.tourPauses || []);
  }, [tourInfos]);

  if (!tourInfo) {
    return <div>Kein Tour</div>;
  }

  //console.log("TourInfo", tourInfo);

  return (
    // <DndContext onDragEnd={handleDragEndMission}>

    <div
      key={tourId}
      className={cn(
        "flex flex-row border-[1px] border-gray-400 p-1 shadow-md",
        {
          "bg-gray-400": tourId === selectedTourId,
        }
        //{ "border-b-8 border-b-blue-500": tourInfo.startMinuteOfDay >= 900 }
      )}
    >
      <div
        key={tourId}
        ref={refDeleteMission}
        className={cn("flex flex-row w-60 gap-2", {
          "bg-red-500": isOverDeleteMission,
        })}
      >
        <div className={cn("flex flex-col gap-2 w-96", {})} key={tourId}>
          <div
            className={cn(
              "flex flex-col justify-center h-full items-center min-w-full bg-white",
              {
                "bg-red-500": isOverDeleteMission,
              }
            )}
          >
            <div
              className={cn("text-xs w-full p-1", {
                "bg-blue-500 text-white": tourInfo.startMinuteOfDay >= 900,
              })}
            >
              {tourId.replace(/_/g, " ")}
            </div>
            <div className="flex flex-row gap-2 items-center p-2 bg-gray-100">
              <Button
                variant={"neutral-ghost"}
                onClick={() => {
                  setTourStartTime(tourId, tourInfo.startMinuteOfDay - 5);
                  _deferredRecalulateTour(tourId);
                }}
              >
                <CircleMinusIcon size={15} />
              </Button>
              {startMin2Time(tourInfo?.startMinuteOfDay || 0)}
              <Button
                variant={"neutral-ghost"}
                onClick={() => {
                  setTourStartTime(tourId, tourInfo.startMinuteOfDay + 5);
                  _deferredRecalulateTour(tourId);
                }}
              >
                <CirclePlusIcon size={15} />
              </Button>
            </div>
            <ChangeMember
              tourId={tourId}
              currentMemberId={tourInfo?.memberId || ""}
            >
              {tourInfo?.member === undefined ? (
                <div>Kein MA</div>
              ) : (
                <DisplayMemberShortName {...tourInfo?.member} />
              )}
              <div>Ändern</div>
            </ChangeMember>
          </div>
          <div className="flex flex-row gap-1 p-1">
            <Dialog>
              <DialogTrigger>
                <Button variant={"neutral-ghost"}>
                  <FilePlusIcon size={15} />
                </Button>
              </DialogTrigger>
              <DialogContent className="w-[80%] h-[80%] overflow-y-scroll">
                <CreateNewMission
                  tourId={tourId}
                  day={tourInfo?.day || ""}
                  member={tourInfo.member}
                />
              </DialogContent>
            </Dialog>
          </div>
        </div>
        <div
          className={cn("flex flex-col gap-1 px-2 items-center bg-white", {})}
        >
          <div className="">
            <Popover>
              <PopoverTrigger>
                <Button variant={"neutral-ghost"} className="text-xs">
                  <div className="flex flex-row gap-1">
                    <div>
                      <Trash className="" size={15} />
                    </div>
                    <div>{notActiveTourMissions.length}</div>
                  </div>
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <div className="flex flex-col gap-2 bg-white p-4 shadow-sm">
                  {notActiveTourMissions.map((mission) => {
                    return (
                      <>
                        <div
                          key={mission.id}
                          className="bg-white w-96 flex flex-row items-center "
                        >
                          <div className="flex flex-col">
                            <div>{mission.patient?.shortName}</div>
                            <div>{startMin2Time(mission.startMinuteOfDay)}</div>
                            <div>{mission.duration_min}</div>
                          </div>
                          <div>
                            <Button
                              onClick={() => {
                                setMissionStatus({
                                  missionId: mission.id,
                                  tourId: tourId,
                                  isActive: true,
                                });
                              }}
                            >
                              Aktivieren
                            </Button>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </PopoverContent>
            </Popover>
          </div>
          <div>
            <Button
              variant={"neutral-ghost"}
              onClick={() => {
                if (tourId === selectedTourId) {
                  setSelectedTourId("");
                } else {
                  setSelectedTourId(tourId);
                }
              }}
            >
              <ReplaceIcon size={20} />
            </Button>
          </div>
          <div>
            <Button
              variant={"neutral-ghost"}
              onClick={() => {
                createTourPause(tourId);
              }}
            >
              <CoffeeIcon size={20} />
            </Button>
          </div>
          <div>
            <Button
              variant={"neutral-ghost"}
              onClick={() => {
                recalculateTour(tourId);
              }}
            >
              <ShipWheelIcon size={20} />
            </Button>
          </div>
          <div
            onClick={() => {
              setTourInfoMessageTourId(tourId);
              setShowTourInfoMessageDialog(true);
            }}
          >
            <Button variant={"neutral-ghost"}>
              <Text size={20} />
            </Button>
          </div>
        </div>
      </div>

      <div
        className={cn("flex flex-row gap-2 w-full flex-wrap bg-gray-100 p-1", {
          // "bg-blue-500": isOver,
        })}
        ref={setNodeRef}
      >
        {/* {timeIntervals.map((timeInterval) => {
          const _missions = tourMissions.filter(
            (mission) =>
              mission.startMinuteOfDay >= timeInterval.startMinuteOfDay &&
              mission.startMinuteOfDay <= timeInterval.endMinuteOfDay
          );

          return (
            <span
              className={cn("flex flex-row p-1", timeInterval.color)}
            ></span>
          );
        })} */}
        {tourMissions.map((mission, index) => {
          let time2Next = 0;

          if (index < tourMissions.length - 1) {
            time2Next =
              tourMissions[index + 1].startMinuteOfDay - mission.endMinuteOfDay;
          }

          if (mission.type === "mission") {
            return (
              <div key={mission.id}>
                <SortableMission
                  mission={mission}
                  tourInfo={tourInfo}
                  time2nextMission={time2Next}
                />
              </div>
            );
          } else {
            return (
              <SortablePause
                key={mission.id}
                pause={mission}
                tourInfo={tourInfo}
                time2nextMission={time2Next}
              />
            );
          }
        })}

        {isOver && <div className="bg-blue-500 text-white">Hier</div>}
      </div>
      <div className="p-2 flex flex-col">
        <div>
          <MapPinIcon
            className={cn("w-4 h-4", {
              "bg-blue-500 text-white": monitorTourIds.includes(tourId),
            })}
            onClick={() => {
              if (monitorTourIds.includes(tourId)) {
                setMonitorTourIds(monitorTourIds.filter((id) => id !== tourId));
              } else {
                setMonitorTourIds([...monitorTourIds, tourId]);
              }
            }}
          />
        </div>
      </div>
    </div>
    //</DndContext>
  );
};

type Props = {};

const MissionEasyPlan = (props: Props) => {
  const {
    missionsMap,
    notActiveMissionsMap,
    selectedShift,
    setSelectedShift,
    loadData,
    selectedPatientId,
    distinctTourIds,
    clearNearestPatients,
    calculateNearestPatients,
    moveMission,
    selectedMissionId,
    setMissionStatus,
    selectedMission,
    day,
    saveAllMissions,
    lastFetchFromDbTS,
    updateMisisonsFromDB,
    countChangedMissions,
    showTourInfoMessageDialog,
    setShowTourInfoMessageDialog,
    tourInfoMessageTourId,
    nextDay,
    prevDay,
    setDay,
    isLoading,
    selectedTourId,
    createSocketMonitor,
    closeSocketMonitor,
  } = useMissionEasyPlan();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 5,
      },
    })
  );

  const [socket, setSocket] = useState<Socket | undefined>(undefined);
  const [activeId, setActiveId] = useState(null);
  const [activePatientShortName, setActivePatientShortName] = useState("");
  const [showMissionAnalytic, setShowMissionAnalytic] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const [count, setCount] = useState(countChangedMissions());

  useEffect(() => {
    console.log("Use effect socket", socket);
    if (!socket) {
      const _socket = io(process.env.REACT_APP_SOCKET_ENDPOINT || "", {
        auth: {
          token: inMemoryJwt.getToken(),
        },
      });
      console.log("socket", _socket);
      _socket.on("connect", () => {
        toast.success("Connected to Server" + _socket.id);

        _socket.on("disconnect", () => {
          toast.error("Disconnected from Server");
        });
      });

      _socket.on("mission-update", (args) => {
        console.log("Mission Update", args);
        toast.success("Mission Update");
        updateMisisonsFromDB();
      });

      setSocket(_socket);
    }
  }, []);

  useEffect(() => {
    createSocketMonitor();

    return () => {
      closeSocketMonitor();
    };
  }, []);

  useEffect(() => {
    setCount(countChangedMissions());
  }, [countChangedMissions, missionsMap, notActiveMissionsMap]);

  useEffect(() => {
    setDay(dayjs().format("YYYY-MM-DD"));

    //  loadData();
  }, []);

  const handleDragEndMission = (event: DragEndEvent) => {
    console.log("handleDragEndMission", event);
    const { active, over } = event;
    if (over?.id === active.id) return;

    if (!over) {
      return;
    }

    if (!active) {
      return;
    }

    if (over?.data.current?.type === "delete") {
      const missionId = active.id as string;
      const tourId = over.data.current.tourId;

      setMissionStatus({
        missionId,
        tourId,
        isActive: false,
      });
      return;
    }

    if (
      (active?.data?.current?.type === "mission" ||
        active?.data?.current?.type === "pause") &&
      over?.data?.current?.type === "mission"
    ) {
      moveMission(
        active.id as string,
        over.id as string,
        active.data.current.mission.tourId,
        over.data.current.mission.tourId
      );
    }

    if (
      active?.data?.current?.type === "mission" &&
      over?.data?.current?.type === "tour"
    ) {
      moveMission(
        active.id as string,
        null,
        active.data.current.mission.tourId,
        over.id as string
      );
    }
  };

  const handleDragOver = (event: DragOverEvent) => {};

  function handleDragStart(event: any) {
    const { active } = event;
    console.log("handleDragStart", active);

    setActiveId(active.id);
    if (active?.data?.current?.mission?.patient) {
      setActivePatientShortName(active.data.current.mission.patient?.shortName);
    } else {
      setActivePatientShortName("Pause");
    }
  }

  const getShiftButtonStyle = (value: boolean) => {
    if (value === true) {
      return "neutral";
    } else {
      return "neutral-ghost";
    }
  };

  const handleCalendarSelect = (date: Date | undefined) => {
    setDay(dayjs(date).format("YYYY-MM-DD"));
    setShowDatePicker(false);
  };

  return (
    <>
      <Helmet>
        <title>TourenEasy {dayjs(day).format("DD.MMM")}</title>
      </Helmet>
      <div className="flex flex-col bg-white w-full overflow-y-hidden h-full  ">
        {/* {showMissionAnalytic && (
        <div className="fixed bottom-0 right-0 m-4 z-50 pointer-events-none">
          <div className="bg-white p-4 rounded-lg shadow-lg pointer-events-auto">
            <DisplayMissionAnalytic missionId={selectedMissionId} />
          </div>
        </div>
      )} */}
        <div className="flex flex-row gap-2 sticky top-0 bg-white justify-between ">
          <div>
            <Button
              onClick={() => {
                clearNearestPatients();
              }}
            >
              Clear
            </Button>
            <Button
              onClick={() => {
                clearNearestPatients();
                calculateNearestPatients(selectedPatientId);
              }}
            >
              Fahren
            </Button>
          </div>

          <div className="flex flex-row gap-2 items-center">
            <div>
              <Dialog>
                <DialogTrigger>
                  <Button disabled={missionsMap === undefined}>
                    Neue Tour
                  </Button>
                </DialogTrigger>
                <DialogContent className="w-[40%] h-[40%] overflow-y-scroll">
                  <CreateNewTour />
                </DialogContent>
              </Dialog>
            </div>
            <div className="flex flex-row gap-2 bg-gray-100 p-2">
              <Button
                disabled={countChangedMissions() !== 0}
                onClick={() => {
                  prevDay();
                }}
              >
                -
              </Button>

              <Popover open={showDatePicker} onOpenChange={setShowDatePicker}>
                <PopoverTrigger asChild>
                  <Button
                    onClick={() => {
                      setShowDatePicker(!showDatePicker);
                    }}
                    variant={"neutral-ghost"}
                  >
                    {dayjs(day).format("dddd, DD. MMMM")}
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <div className="bg-white">
                    <Calendar
                      mode="single"
                      selected={dayjs(day).toDate()}
                      onSelect={handleCalendarSelect}
                      initialFocus
                    />
                  </div>
                </PopoverContent>
              </Popover>

              <Button
                disabled={countChangedMissions() !== 0}
                onClick={() => {
                  nextDay();
                }}
              >
                +
              </Button>
            </div>
            <div>
              <Button
                disabled={countChangedMissions() !== 0}
                onClick={() => {
                  setSelectedShift({
                    early: !selectedShift.early,
                    late: selectedShift.late,
                  });
                }}
                variant={getShiftButtonStyle(selectedShift.early)}
              >
                Früh
              </Button>
              <Button
                disabled={countChangedMissions() !== 0}
                onClick={() => {
                  setSelectedShift({
                    early: selectedShift.early,
                    late: !selectedShift.late,
                  });
                }}
                variant={getShiftButtonStyle(selectedShift.late)}
              >
                Spät
              </Button>
            </div>
            <div>
              <Button
                onClick={() => {
                  loadData();
                }}
              >
                Laden
              </Button>
            </div>
            <div>
              <Button
                onClick={() => {
                  saveAllMissions();
                }}
              >
                <div className="flex flex-row gap-2 items-center">
                  Speichern
                  <span className="bg-blue-500 text-white rounded-2xl px-2">
                    {countChangedMissions()}
                  </span>
                </div>
              </Button>
            </div>
          </div>
          <div>
            <Button
              onClick={() => {
                setShowMissionAnalytic(!showMissionAnalytic);
              }}
            >
              Analyse
            </Button>
          </div>

          {/* <div>{lastFetchFromDbTS}</div>
        <Button
          onClick={() => {
            updateMisisonsFromDB();
          }}
        >
          Update
        </Button> */}
        </div>

        <div className="flex flex-row overflow-y-scroll ">
          <div className="flex flex-col gap-2 w-full h-full overflow-y-scroll  ">
            <DndContext
              sensors={sensors}
              onDragEnd={handleDragEndMission}
              onDragOver={handleDragOver}
              onDragStart={handleDragStart}
            >
              {selectedTourId !== "" && (
                <div className="sticky top-0 bg-white">
                  <SortableTour tourId={selectedTourId} />
                </div>
              )}
              {distinctTourIds
                .filter((o) => o !== selectedTourId)
                .map((tourId) => {
                  return <SortableTour key={tourId} tourId={tourId} />;
                })}
              <DragOverlay>
                {activeId ? (
                  <div id={activeId}>{activePatientShortName}</div>
                ) : null}
              </DragOverlay>
            </DndContext>
          </div>
          {showMissionAnalytic && selectedMissionId && (
            <div className="w-96 flex flex-col">
              <EditMission
                missionId={selectedMissionId}
                tourId={selectedTourId}
                type={selectedMission?.type || "unbekannt"}
              />
            </div>
          )}
        </div>
        <TourInfoMessageDialog
          day={day}
          tourId={tourInfoMessageTourId}
          isOpen={showTourInfoMessageDialog}
          setIsOpen={setShowTourInfoMessageDialog}
        />
      </div>
      <MyDimmer open={isLoading} />
    </>
  );
};

export default MissionEasyPlan;
