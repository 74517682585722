import produce from "immer";
import { create } from "zustand";
import { ActionWithQuantity } from "../../generated/graphql";
import { LocalActionWithQuantity } from "../../type-definitions";

import { graphql } from "../../gql";
import { client } from "../../urqlClient";
import { ArrElement } from "../../helpers/typeHelpers";

import {
  AllActionsDocument,
  AllActionsQuery,
  AllActionsQueryVariables,
} from "../../gql/graphql";
import _ from "lodash";

export const AllActions = graphql(/* GraphQL */ `
  query AllActions {
    actions(filter: { executable: true }) {
      leistungsart
      leistungskomplex
      lnr
      examinationRequired
      executable
      leistungsinhalte
      requiredQualificationLevel
    }
  }
`);

const PauschGrundPflegeComplexIds = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "16",
  "16a",
  "17",
  "27",
  "28",
  "30",
];
const TimedComplexIds = ["31", "32", "33", "200"];
const BehandlungsFavorites = [];
const SonderIds = ["zAn"];

export type LocalAllAction = ArrElement<AllActionsQuery["actions"]>;

export type LoacalActionWithQuantity = {
  action: LocalAllAction;
  quantity: number;
  actionLnr: string;
};

interface IActionClipboard {
  actionsClipboardContent: LocalActionWithQuantity[];
  getActionClipbaordContentAsCoded: () => string;
  setActionClipboardContent: (actionsClipboardContent: any[]) => void;
  clearActionClipboardContent: () => void;
  toggleActionClipboardContent: (action: any) => void;
  addOneToAction: (action: any) => void;
  subOneToAction: (action: any) => void;

  allActions: LocalAllAction[];
  grundPflegeActions: LocalAllAction[];
  behandlungPflegeActions: LocalAllAction[];
  timedActions: LocalAllAction[];
  selectedActions: LocalActionWithQuantity[];
  loadAllActions: () => void;
  setAllActions: (actions: LocalAllAction[]) => void;
  setFilter: (filter: string) => void;
  filter: string | undefined;
  filteredBehandlungPflegeActions: LocalAllAction[];
  sonderActions: LocalAllAction[];
}

export const useActionClipboard = create<IActionClipboard>((set, get) => ({
  actionsClipboardContent: [],
  allActions: [],
  filter: undefined,
  filteredBehandlungPflegeActions: [],
  grundPflegeActions: [],
  behandlungPflegeActions: [],
  selectedActions: [],
  timedActions: [],
  sonderActions: [],
  loadAllActions: async () => {
    const ret = await client.query<AllActionsQuery>(AllActions, {}).toPromise();

    const _grundActions = ret.data?.actions
      ?.filter((a) => {
        if (a === null || a === undefined) return false;
        return PauschGrundPflegeComplexIds.includes(a.leistungskomplex);
      })
      .sort((a, b) => {
        if (a === null || a === undefined) return 0;
        if (b === null || b === undefined) return 0;
        return a.leistungskomplex.localeCompare(b.leistungskomplex);
      });

    const _timedActions = ret.data?.actions
      ?.filter((a) => {
        if (a === null || a === undefined) return false;
        return TimedComplexIds.includes(a.leistungskomplex);
      })
      .sort((a, b) => {
        if (a === null || a === undefined) return 0;
        if (b === null || b === undefined) return 0;
        return a.leistungskomplex.localeCompare(b.leistungskomplex);
      });

    const _behandlungActions = ret.data?.actions
      ?.filter((a) => {
        if (a === null || a === undefined) return false;
        return a.examinationRequired;
      })
      .sort((a, b) => {
        if (a === null || a === undefined) return 0;
        if (b === null || b === undefined) return 0;
        return a.leistungskomplex.localeCompare(b.leistungskomplex);
      });

    const _sonderActions = ret.data?.actions
      ?.filter((a) => {
        if (a === null || a === undefined) return false;
        return SonderIds.includes(a.leistungskomplex);
      })
      .sort((a, b) => {
        if (a === null || a === undefined) return 0;
        if (b === null || b === undefined) return 0;
        return a.leistungskomplex.localeCompare(b.leistungskomplex);
      });

    set({
      allActions: ret.data?.actions || [],
      grundPflegeActions: _grundActions || [],
      timedActions: _timedActions || [],
      behandlungPflegeActions: _behandlungActions || [],
      filteredBehandlungPflegeActions: _behandlungActions || [],
      sonderActions: _sonderActions || [],
    });
  },
  setAllActions: (actions: LocalAllAction[]) => {
    set({
      allActions: actions,
    });
  },
  setActionClipboardContent: (
    actionsClipboardContent: LocalActionWithQuantity[]
  ) => set(() => ({ actionsClipboardContent })),
  clearActionClipboardContent: () =>
    set(() => ({ actionsClipboardContent: [] })),
  toggleActionClipboardContent: (action: LocalActionWithQuantity) => {
    console.log(action);
    const actionsClipboardContent = get().actionsClipboardContent;

    const newActionClipboardContent = produce(
      actionsClipboardContent,
      (draft) => {
        const index = draft.findIndex(
          (a) => a.action.lnr === action.action.lnr
        );
        if (index !== -1) {
          draft.splice(index, 1);
        } else {
          draft.push(action);
        }
        return draft;
      }
    );
    set(() => ({ actionsClipboardContent: newActionClipboardContent }));
  },
  addOneToAction: (action: LocalActionWithQuantity) => {
    const actionsClipboardContent = get().actionsClipboardContent;

    const newActionClipboardContent = produce(
      actionsClipboardContent,
      (draft) => {
        const index = draft.findIndex(
          (a) => a.action.lnr === action.action.lnr
        );
        if (index !== -1) {
          draft[index].quantity += 1;
        }
        return draft;
      }
    );
    set(() => ({ actionsClipboardContent: newActionClipboardContent }));
  },
  subOneToAction: (action: LocalActionWithQuantity) => {
    const actionsClipboardContent = get().actionsClipboardContent;

    const newActionClipboardContent = produce(
      actionsClipboardContent,
      (draft) => {
        const index = draft.findIndex(
          (a) => a.action.lnr === action.action.lnr
        );
        if (index !== -1) {
          draft[index].quantity -= 1;
        }
        return draft;
      }
    );
    set(() => ({ actionsClipboardContent: newActionClipboardContent }));
  },
  setFilter: (filter: string) => {
    if (filter === undefined || filter === null || filter === "") {
      set(() => ({
        filter: filter,
        filteredBehandlungPflegeActions: get().behandlungPflegeActions,
      }));
      return;
    }
    const newBehandlungsPflegeActions = _.filter(
      get().behandlungPflegeActions,
      (v) => {
        let found = false;
        const expression = new RegExp(filter, "i");
        if (v?.leistungskomplex.indexOf(filter) !== -1) found = true;
        if (v?.leistungsart.match(expression)) found = true;
        return found;
      }
    );
    set(() => ({
      filter: filter,
      filteredBehandlungPflegeActions: newBehandlungsPflegeActions,
    }));
  },
  getActionClipbaordContentAsCoded: () => {
    const actionsClipboardContent = get().actionsClipboardContent;

    //console.log(actionsClipboardContent);
    if (
      actionsClipboardContent === undefined ||
      actionsClipboardContent === null ||
      actionsClipboardContent.length === 0
    )
      return " ";

    const actionsClipboardContentAsCoded = actionsClipboardContent
      .map((a) => {
        return `${a.quantity}x${a.action.lnr}`;
      })
      .join("_");
    return actionsClipboardContentAsCoded;
  },
}));
